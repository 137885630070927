<template>
  <div class="_pc-pad-x-260 _flex-row _white-bg _mb-pad-x-30 _mb-grey-bg">

    <!-- 导航栏 -->
    <div class="_pc-pad-top-40 _mb-pad-top-20 _min-height _pc-mar-right-40 _pc-w-250 over-auto wow fadeInLeft _mb-display-none">
      <div>
        <div class="_pc-mar-bottom-25 _mb-mar-bottom-5" v-for="(item,index) in list" :key="index">
          <div class="_pc-font-18 _b " style="cursor:pointer;" @click="giv(item.id)"><img class="_pc-w-10 _pc-mar-right-10" :style="item.ishow?'':'transform:rotate(-90deg);'" src="@/assets/image/down-ico.png" alt="">{{item.title}}</div>
          
          <div class=" _pc-mar-top-25 _mb-mar-top-5 _border-left-nav" v-if="item.ishow">
            <div class="_pc-font-16 _pos-rel _flex-row-mid _pc-pad-y-5  _pc-mar-bottom-15 _mb-mar-bottom-5" style="cursor:pointer;" v-for="(val,key) in item.artcile" :key="key" @click="get_id(val.id,item.id)">
              <div class="_pc-pad-x-20 " :class="id==val.id?'color-blue':''">{{val.title}}</div> 
              <div v-if="id ==val.id" class=" _h-max _pos-abs-left"></div>
            </div>
          </div>

        </div>

      </div>
    </div>
    


    <!-- 展示处 -->
    <div class="_w-max grey-bg  _pc-pad-top-40 _mb-pad-top-20 _pc-pad-bottom-10 _mb-pad-bottom-5">
      <div class="_pc-mar-x-40 _pos-rel">
        <router-view  :nav_list = 'list' @giv='giv' @get_id='get_id'></router-view>
        <div class="_w-max _pc-mar-top-50 _mb-mar-top-30 _h-5-v" ></div>
      </div>
    </div>


  </div>
</template>

<script>
export default {
  name: 'PcWebIndex',

  data() {
    return {
      id:0,
      checked:true,
      list:[],
      time:null,
    };
  },

  watch:{
    $route:{
      deep:true,
      handler(newval,oldval){
        this.id = newval.query.id;
      },
    }
  },


  mounted() {
    if(this.$route.query.id){
      this.id=this.$route.query.id;
    }
    this.get_news();

    setTimeout(()=>{
      new  this.$wow.WOW().init()
    },20)
  },

  methods: {
    giv(id){
      clearTimeout(this.time);
      this.time = setTimeout(()=>{
        this.list.forEach((val)=>{
        if(val.id == id){
          val.ishow = !val.ishow ;
        }
      })
      },100)
    },

    get_id(id,f_id){
      if(this.checked){
      }else{return}
      if(id == this.$route.query.id){return}
      this.$router.push({path:'/question/detail',query:{id,f_id}})
    },

    async get_news(){  //获取常见问题列表
      let that = this;
      await that.$axios.get('get_article_tree?id=14',{}).then(res=>{
        res.data.data[0].child.forEach(val=>{
          val.ishow = false;
        })

        that.list = res.data.data[0].child
      })
    },
    
  },
};
</script>

<style scoped>


  ._min-height{
    height: 80vh;
  }
  .over-auto{
    overflow: auto;
  }
  .grey-bg{background: #F7F7F7;}

  ._border-right-red{
    border: 1px solid #E21F19;
  }

  ._border-left-nav{
    border-left: 1px solid #DEDEDE;
  }
  .color-blue{
    color:var(--theme-color);
  }

._h-5-v{height: 5vh;}

@media screen and (max-width: 768px) {
  ._h-5-v{height: 11.41553vh;}
  ._mb-grey-bg{background: #F7F7F7;}
}
</style>